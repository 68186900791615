import { 
  Box, 
  Flex,
  Text,
  InputGroup,
  InputRightAddon,
  Button,
  Input,
  Stack,
  FormControl,
  FormLabel
} from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import { useRef, useState } from 'react';
import axios from 'axios';
import { FaRegEye } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { userAtom } from '../recoil/userAtoms';
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { firebaseApp } from '../firebase/firebaseConfig';
import logo from '../files/MOLO boost logo.png';
import SocialMediaWrapper from '../components/SMWrapper';

const LoginComponent = () => {
const emailRef = useRef();
const passwordRef = useRef();
const history = useNavigate();
const [_, setUser] = useRecoilState(userAtom);
const [showPassword, setShowPassword] = useState(false);
const [isDataLoading, setDataLoading] = useState(false);
const [err201, setErr201] = useState(false);

const handleEmailPasswordLogin = () => {
  setDataLoading(true);
  const auth = getAuth(firebaseApp);
  signInWithEmailAndPassword(auth, emailRef.current.value.trim(), passwordRef.current.value.trim())
    .then((userCredential) => {
      const user = userCredential.user;
      axios.post('https://molo-boost-backend-27551f6a06a7.herokuapp.com/api/login', { email: user.email })
        .then(res => {
          setUser(res.data.user);
          history('/dashboard/reviews?from=login');
        })
        .catch((error) => {
          setErr201(true)
          console.log(error);
        })
        .finally(() => setDataLoading(false));
    })
    .catch((error) => {
      setErr201(true);
      console.log(error);
      setDataLoading(false);
    });
};

const handleGoogleSignIn = async () => {
  setDataLoading(true);
  const auth = getAuth(firebaseApp);
  const provider = new GoogleAuthProvider();

  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;

    axios.post('https://molo-boost-backend-27551f6a06a7.herokuapp.com/api/login', {
      email: user.email,
      firebaseUID: user.uid
    }).then(res => {
      setUser(res.data.user);
      history('/dashboard/reviews?from=google-login');
    }).catch((error) => {
      setErr201(true);
      console.log(error);
    }).finally(() => setDataLoading(false));

  } catch (error) {
    console.error("Google Sign-In Error:", error.message);
    setErr201(true);
    setDataLoading(false);
  }
};

return (
  <SocialMediaWrapper>
    <Box w="100%" pt={6} pb={6}>
      <Flex w={['95%', '60%']} m="0 auto" bg="white" borderRadius="8px" border="solid 3px #eeecea">
        <form style={{ width: '100%' }}>
          <Stack spacing={4} p={[4, '2rem']}>
            {/* Logo and Header */}
            <Text margin="0 auto" color="#6da34d" textAlign="center" fontSize="2rem" fontWeight="900">
              Log in
            </Text>

            {/* Google Sign-In */}
            <Text color="#000" textAlign="center" fontSize="1.2rem" fontWeight="600">
              Sign in with your email or Google account
            </Text>
            <Flex justifyContent="center" mb={4}>
              <Button
                onClick={handleGoogleSignIn}
                bg="white"
                outline="2px solid #4285F4"
                color="#4285F4"
                _hover={{ bg: "#b7d4ff" }}
                w={['100%', '50%']}
                leftIcon={
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/768px-Google_%22G%22_logo.svg.png"
                    alt="Google"
                    style={{ width: '20px' }}
                  />
                }
              >
                Sign In with Google
              </Button>
            </Flex>

            {/* Divider */}
            <Flex alignItems="center" justifyContent="center" my={4}>
              <Box flex="1" height="1px" bg="#ccc" />
              <Text mx={2} color="#666" fontSize="0.9rem" fontWeight="500">
                OR
              </Text>
              <Box flex="1" height="1px" bg="#ccc" />
            </Flex>

            {/* Email and Password Login */}
            {err201 && (
              <Text color="red.300" textAlign="center">
                Something went wrong. Check your inputs and try again.
              </Text>
            )}

            <FormControl isRequired>
              <FormLabel color="#000">Email Address</FormLabel>
              <Input ref={emailRef} type="email" />
            </FormControl>

            <FormControl isRequired>
              <FormLabel color="#000">Password</FormLabel>
              <InputGroup>
                <Input ref={passwordRef} type={showPassword ? 'text' : 'password'} />
                <InputRightAddon
                  color="black"
                  _hover={{ cursor: 'pointer' }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <FaRegEye />
                </InputRightAddon>
              </InputGroup>
            </FormControl>

            <Button
              isLoading={isDataLoading}
              onClick={handleEmailPasswordLogin}
              bg="#5A7BFF"
              color="white"
              _hover={{ bg: "#5A7BFF" }}
              fontWeight="700"
            >
              SIGN IN
            </Button>

            <Link to="/signup/form">
              <Text textAlign="center" color="#6da34d" fontSize="1rem" style={{ textDecoration: 'underline' }}>
                Don't have an account yet? Sign up
              </Text>
            </Link>

            <Link to="/reset">
              <Text textAlign="center" color="#6da34d" fontSize="1rem" style={{ textDecoration: 'underline' }}>
                Forgot your password? Reset
              </Text>
            </Link>
          </Stack>
        </form>
      </Flex>
    </Box>
    {/* Privacy Policy Link */}
    <Text
    textAlign="center"
    color="#666"
    fontSize="0.9rem"
    position="absolute"
    bottom="10px"
    w="100%"
  >
    By signing in, you agree to our{' '}
    <a href="/privacy-policy" style={{ color: '#5A7BFF', textDecoration: 'underline' }}>
      Privacy Policy
    </a>.
  </Text>
  </SocialMediaWrapper>
);
};

export default LoginComponent;
