import Sidebar from '../components/Sidebar';
import { 
    Box, 
    Flex,
    Text,
    Button,
    Skeleton,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import DataTableExample from '../components/dataTables';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../recoil/userAtoms';

export default function Reviews() {
    const [reviews, setReviews] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [placesId, setPlacesId] = useState(null);
    const [reviewLink, setReviewLink] = useState(null);
    const user = useRecoilValue(userAtom);


    const getReviews = () => {
        axios.get('https://molo-boost-backend-27551f6a06a7.herokuapp.com' + "/api/reviews/get/" + user.email)
            .then(res => {
                const reviews = res.data.reviews;
                const placesId = res.data.places_id;
                const reviewLink = res.data.reviewLink;

                reviews.forEach(review => {
                    if (review.reviewText.length > 50) {
                        review.reviewText = review.reviewText.substring(0, 50) + '...';
                    }
                });

                setReviews(reviews);
                setPlacesId(placesId);
                setReviewLink(reviewLink)
                setDataLoaded(true);
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handleRedirectToGoogleReviews = () => {
        if (placesId) {
            window.open(reviewLink, '_blank');
        } else {
            alert("Places ID not available.");
        }
    };
    

    useEffect(() => {
        getReviews();
    }, []);

    const columnsData = [
        {
            Header: "NAME",
            accessor: "name"
        },
        {
            Header: "EMAIL",
            accessor: "email"
        },
        {
            Header: "PHONE",
            accessor: "phone"
        },
        {
            Header: "STAR RATING",
            accessor: "rating"
        },
        {
            Header: "FEEDBACK/COMMENTS",
            accessor: "reviewText"
        },
    ];

    return (
        <Flex w="100%">
            <Sidebar />
            <Box p={4} w='full' pb={0}>
                <Box w='100%'>
                    <Text textAlign={'center'} color='#000' fontSize='2rem' fontWeight='800'>Reviews</Text>
                    <Text textAlign={'center'} color='gray.500' fontSize='1rem' fontWeight='500'>
                        View and manage the reviews submitted by your customers.
                    </Text>

                    {/* Button to redirect to Google Reviews */}
                    {/* {placesId && ( */}
                        <Box textAlign="center" my={4}>
                            <Button 
                                colorScheme="blue" 
                                onClick={handleRedirectToGoogleReviews}
                            >
                                Visit Google Reviews
                            </Button>
                        </Box>
                    {/* )} */}

                    <Box>
                        <Flex direction={'column'} m={1} mb='8vh'>
                            <Skeleton isLoaded={dataLoaded}>
                                <Box>
                                    <DataTableExample
                                        tableData={reviews}
                                        columnsData={columnsData}
                                        tableType='Reviews'
                                    />
                                </Box>
                            </Skeleton>
                        </Flex>
                    </Box>
                </Box>
            </Box>
        </Flex>
    );
}
