import { useState } from 'react';
import { Box, Tabs, TabList, TabPanels, Tab, TabPanel, Text } from '@chakra-ui/react';

const PrivacyPolicyPage = () => {
    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (index) => {
        setSelectedTab(index);
    };

    return (
        <Box w="100%" p={8}>
            <Tabs index={selectedTab} onChange={handleTabChange} variant="enclosed">
                <TabList>
                    <Tab>English</Tab>
                    <Tab>French</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <Text fontSize="md" whiteSpace="pre-wrap" lineHeight="1.6">
                            {/* English Text */}
                            {`WEBSITE PRIVACY POLICY

The Internet is an amazing tool. It has the power to change the way we live, and we're starting to see that potential today. With only a few mouse-clicks, you can follow the news, look up facts, buy goods and services, and communicate with others from around the world. It's important to MOLO boost (9522-9076 Québec inc.) to help our customers retain their privacy when they take advantage of all the Internet has to offer.

We believe your business is no one else's. Your privacy is important to you and to us. So we'll protect the information you share with us. To protect your privacy, MOLO boost (9522-9076 Québec inc.) follows different principles in accordance with worldwide practices for customer privacy and data protection.

❑ We won’t sell or give away your name, mail address, phone number, email address or any other information to anyone.

❑ We’ll use state-of-the-art security measures to protect your information from unauthorized users.

NOTICE

We will ask you when we need information that personally identifies you (personal information) or allows us to contact you. Generally, this information is requested when you create a Registration ID on the site or when you download free software, enter a contest, order email newsletters or join a limited-access premium site. We use your Personal Information for four primary purposes:

❑ To make the site easier for you to use by not having to enter information more than once.

❑ To help you quickly find software, services or information.

❑ To help us create content most relevant to you.

❑ To alert you to product upgrades, special offers, updated information and other new services from MOLO boost (9522-9076 Québec inc.).

CONSENT

If you choose not to register or provide personal information, you will not be able to use most of moloboost.ca and to access areas that require registration.

If you decide to register, you will be able to select the kinds of information you want to receive from us by subscribing to various services, like our electronic newsletters. If you do not want us to communicate with you about other offers regarding MOLO boost (9522-9076 Québec inc.) products, programs, events, or services by email, postal mail, or telephone, you may select the option stating that you do not wish to receive marketing messages from MOLO boost (9522-9076 Québec inc.).

MOLO boost (9522-9076 Québec inc.) occasionally allows other companies to offer our registered customers information about their products and services, using postal mail only. If you do not want to receive these offers, you may select the option stating that you do not wish to receive marketing materials from third parties.

ACCESS

We will provide you with the means to ensure that your personal information is correct and current. You may review and update this information at any time at the Visitor Center. There, you can:

❑ View and edit personal information you have already given us.

❑ Tell us whether you want us to send you marketing information, or whether you want third parties to send you their offers by postal mail.

❑ Sign up for electronic newsletters about our services and products.

❑ Register. Once you register, you won't need to do it again. Wherever you go on moloboost.ca, your information stays with you.

SECURITY

MOLO boost (9522-9076 Québec inc.) has taken strong measures to protect the security of your personal information and to ensure that your choices for its intended use are honored. We take strong precautions to protect your data from loss, misuse, unauthorized access or disclosure, alteration, or destruction.

We guarantee your e-commerce transactions to be 100% safe and secure. When you place orders or access your personal account information, you're utilizing secure server software SSL, which encrypts your personal information before it's sent over the Internet. SSL is one of the safest encryption technologies available.

In addition, your transactions are guaranteed under the Fair Credit Billing Act. This Act states that your bank cannot hold you liable for more than $50.00 in fraudulent credit card charges. If your bank does hold you liable for $50.00 or less, we'll cover your liability provided the unauthorized, fraudulent use of your credit card resulted through no fault of your own and from purchases made from us over our secure server. In the event of unauthorized use of your credit card, you must notify your credit card provider in accordance with its reporting rules and procedures.

MOLO boost (9522-9076 Québec inc.) strictly protects the security of your personal information and honors your choices for its intended use. We carefully protect your data from loss, misuse, unauthorized access or disclosure, alteration, or destruction.

Your personal information is never shared outside the company without your permission, except under conditions explained above. Inside the company, data is stored in password-controlled servers with limited access. Your information may be stored and processed in Canada or any other country where MOLO boost (9522-9076 Québec inc.), its subsidiaries, affiliates or agents are located.

You also have a significant role in protecting your information. No one can see or edit your personal information without knowing your username and password, so do not share these with others.

NOTICE TO PARENTS

Parents or guardians: we want to help you guard your children's privacy. We encourage you to talk to your children about safe and responsible use of their Personal Information while using the Internet.

The MOLO boost (9522-9076 Québec inc.) site does not publish content that is targeted to children. However, if you are concerned about your children providing MOLO boost (9522-9076 Québec inc.) any personal information without your consent, MOLO boost (9522-9076 Québec inc.) offers a Kids account. It allows parents to give parental consent for the collection, use and sharing of children’s (ages 12 and under) personal information online.

ENFORCEMENT

If for some reason you believe MOLO boost (9522-9076 Québec inc.) has not adhered to these principles, please notify us by email at info@molostrategies.ca, and we will do our best to determine and correct the problem promptly. Be certain the words Privacy Policy are in the Subject line.

ELECTRONIC PRODUCT REGISTRATION

When you buy and install a new product, we may ask you to register your purchase electronically. When you do, we merge your registration information with any information you've already left with us (we call that information your personal profile). If you haven't previously registered with us, we create a personal profile for you from your product registration information. If you ever want to review or update that information, you can visit the Profile Center, click on Update Profile, and edit any of the Personal Information in your profile. If you haven't already created a Registration ID, we will ask you to do so. This ensures that only you can access your information.

CUSTOMER PROFILES

As mentioned above, every registered customer has a unique personal profile. Each profile is assigned a unique personal identification number, which helps us ensure that only you can access your profile.

When you register, we create your profile, assign a personal identification number, then send this personal identification number back to your hard drive in the form of a cookie, which is a very small bit of code. This code is uniquely yours. It is your passport to seamless travel across moloboost.ca, allowing you to download free software, order free newsletters, and visit premium sites without having to fill out registration forms with information you've already provided. Even if you switch computers, you won't have to re-register – just use your Registration ID to identify yourself.

WHAT WE DO WITH THE INFORMATION YOU SHARE

When you join us, you provide us with your contact information, including your email address. We use this information to send you updates about your order, questionnaires to measure your satisfaction with our service and announcements about new and exciting services that we offer. When you order from us, we ask for your credit card number and billing address. We use this information only to bill you for the product(s) you order at that time. For your convenience, we do save billing information in case you want to order from us again, but we don't use this information again without your permission.

We occasionally hire other companies to provide limited services on our behalf, including packaging, mailing and delivering purchases, answering customer questions about products or services, sending postal mail and processing event registration. We will only provide those companies the information they need to deliver the service, and they are prohibited from using that information for any other purpose.

MOLO boost (9522-9076 Québec inc.) will disclose your personal information, without notice, only if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on MOLO boost (9522-9076 Québec inc.) or the site; (b) protect and defend the rights or property of MOLO boost (9522-9076 Québec inc.) and its family of Websites, and, (c) act in urgent circumstances to protect the personal safety of users of MOLO boost (9522-9076 Québec inc.), its Websites, or the public.`}   
                        </Text>
                    </TabPanel>
                    <TabPanel>
                        <Text fontSize="md" whiteSpace="pre-wrap" lineHeight="1.6">
                            {/* French Text */}
                            {`POLITIQUE DE CONFIDENTIALITÉ

MOLO boost (9522-9076 Québec inc.) s’engage formellement à protéger la confidentialité des informations fournies par ses clients et à respecter leurs choix. L’information personnelle et non personnelle que nous collectons est protégée par des mesures de sécurité rigoureuses et selon les normes internationales sur la protection des renseignements personnels. Soyez assuré que:

❑ Aucune information personnelle telle que votre nom, adresse postale, numéro de téléphone ou adresse courriel ne sera vendue ni transmise à des tiers.

❑ Des mesures de sécurité à la fine pointe de la technologie seront utilisées pour protéger vos informations contre les accès non autorisés.

AVIS

Nous vous demanderons uniquement des informations personnelles qui permettent de vous identifier ou de vous contacter lorsque cela sera nécessaire. Ces informations sont généralement requises lorsque vous créez un identifiant sur le site, téléchargez un logiciel gratuit, participez à un concours, vous inscrivez à une infolettre ou accédez à des zones à accès restreint. Les informations personnelles collectées sont utilisées principalement pour:

❑ Simplifier l’utilisation du site en évitant d’avoir à saisir plusieurs fois les mêmes informations.

❑ Vous aider à trouver rapidement des services, produits ou informations.

❑ Adapter le contenu en fonction de vos intérêts.

❑ Vous informer des mises à jour, offres spéciales, nouveaux services et autres informations reliées à MOLO boost (9522-9076 Québec inc.).

CONSENTEMENT

Si vous choisissez de ne pas vous inscrire ou de ne pas fournir vos informations personnelles, l’accès à certaines fonctionnalités ou sections du site moloboost.ca pourrait être limité.

En vous inscrivant, vous aurez la possibilité de sélectionner les types d’informations que vous souhaitez recevoir, notamment via nos infolettres électroniques. Si vous préférez ne pas recevoir d’offres ou d’informations liées aux produits, services, programmes ou événements de MOLO boost (9522-9076 Québec inc.), vous pourrez indiquer vos préférences.

Dans certains cas, MOLO boost (9522-9076 Québec inc.) permet à des entreprises tierces de proposer à ses clients enregistrés des informations sur leurs produits et services par voie postale uniquement. Si vous ne souhaitez pas recevoir ces offres, vous pouvez également l’indiquer.

ACCÈS

Vous aurez les moyens de vous assurer que vos informations personnelles sont exactes et à jour. Vous pourrez à tout moment:

❑ Consulter et modifier les informations personnelles déjà fournies.

❑ Indiquer vos préférences concernant la réception d’informations marketing de notre part ou de la part de tiers.

❑ Vous inscrire à des infolettres électroniques sur nos produits et services.

❑ Accéder à votre compte sans avoir à vous inscrire de nouveau, vos informations restant disponibles sur moloboost.ca.

SÉCURITÉ

MOLO boost (9522-9076 Québec inc.) met en œuvre des mesures rigoureuses pour garantir la sécurité de vos informations personnelles et s’assurer que vos choix concernant leur utilisation sont respectés. Vos données sont protégées contre toute perte, utilisation abusive, accès non autorisé, modification ou destruction.

Les transactions effectuées sur notre site sont sécurisées à 100%. Les commandes et informations personnelles sont transmises via un serveur sécurisé utilisant la technologie SSL, qui crypte les données avant leur transmission. Cette technologie est l’une des plus sûres disponibles.

En cas d’utilisation non autorisée de votre carte de crédit dans le cadre d’une transaction sur notre serveur sécurisé, votre responsabilité est limitée à 50$, conformément à la Loi sur la facturation équitable. Si votre banque vous tient responsable pour cette somme, nous couvrirons ce montant, sous réserve que l’utilisation frauduleuse ne résulte pas de votre faute et qu’elle ait été effectuée via notre site sécurisé. Vous devrez toutefois signaler cette utilisation à votre institution financière selon les procédures applicables.

À l’interne, vos données sont stockées sur des serveurs sécurisés, protégés par des mots de passe et accessibles uniquement à un personnel autorisé.

AVIS AUX PARENTS

MOLO boost (9522-9076 Québec inc.) encourage les parents à sensibiliser leurs enfants à une utilisation sécuritaire et responsable de leurs informations personnelles sur Internet. Le site ne publie pas de contenu spécifiquement destiné aux enfants. Cependant, pour les enfants de 12 ans et moins, un compte spécial peut être créé sous le contrôle des parents. Celui-ci permet aux parents de donner leur consentement quant à la collecte et à l’utilisation des données personnelles des enfants.

APPLICATION DE LA POLITIQUE

Pour toute question ou préoccupation concernant le respect de ces principes, il est possible de nous contacter par courriel à info@molostrategies.ca. Veuillez inclure « Politique de confidentialité » dans l’objet du message. Nous nous engageons à répondre rapidement et à résoudre tout problème dans les meilleurs délais.

ENREGISTREMENT ÉLECTRONIQUE DES PRODUITS

Lorsqu’un produit est acheté et installé, il peut être demandé d’enregistrer cet achat de manière électronique. Lors de cet enregistrement, les informations fournies sont fusionnées avec celles déjà enregistrées dans nos systèmes (désignées sous le terme de « profil personnel »). Si aucun enregistrement préalable n’a été effectué, un profil personnel est créé à partir des informations fournies lors de l’enregistrement du produit.

Pour consulter ou mettre à jour ces informations, il est possible de se rendre au Centre de profils, cliquer sur « Mettre à jour le profil » et modifier les informations personnelles. Si aucun identifiant d’enregistrement n’a été créé auparavant, il sera demandé d’en créer un. Cela garantit que seules les personnes autorisées peuvent accéder à ces informations.

PROFILS DES CLIENTS

Comme mentionné précédemment, chaque client enregistré dispose d’un profil personnel unique. Un numéro d’identification personnel unique est attribué à chaque profil pour garantir un accès sécurisé et exclusif.

Lors de l’enregistrement, un profil est créé, un numéro d’identification personnel est attribué, et ce numéro est ensuite transmis sur le disque dur de l’utilisateur sous la forme d’un témoin (« cookie »), un petit fichier de code informatique. Ce code est propre à l’utilisateur. Il agit comme un passeport permettant de naviguer facilement sur le site moloboost.ca, d’accéder à des téléchargements gratuits, de commander des infolettres et de visiter des sections premium sans avoir à remplir de nouveaux formulaires d’enregistrement avec des informations déjà fournies. Même en changeant d’ordinateur, il n’est pas nécessaire de s’enregistrer à nouveau: il suffit d’utiliser l’identifiant d’enregistrement pour s’identifier.

UTILISATION DES INFORMATIONS PARTAGÉES

Les informations personnelles que vous partagez, comme votre adresse courriel, sont utilisées pour vous tenir informé de l’état de vos commandes, mesurer votre satisfaction via des questionnaires et vous communiquer les nouveautés ou promotions. Vos informations bancaires ne sont utilisées que pour le traitement des paiements et, à votre demande, peuvent être sauvegardées pour des commandes futures.

MOLO boost (9522-9076 Québec inc.) peut employer des entreprises tierces pour des services spécifiques (expédition, gestion des inscriptions à des événements, etc.). Ces entreprises reçoivent uniquement les informations nécessaires pour accomplir leur mission et ne peuvent pas les utiliser à d’autres fins.

Cette politique respecte les lois applicables au Québec et au Canada en matière de protection des renseignements personnels.`}
                        </Text>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    );
};

export default PrivacyPolicyPage;
