import Sidebar from '../components/Sidebar';
import { 
    Box, 
    Flex,
    Text,
    Button,
    Image,
    Skeleton,
} from '@chakra-ui/react';

import QRChart from '../components/QRChart';
import { AiFillPrinter } from "react-icons/ai";
import { useEffect, useState } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../recoil/userAtoms';
import { useLocation } from 'react-router-dom';

export default function QRPage() {
    const [qrData, setQrData] = useState({});
    const [dates, setDates] = useState([]);
    const [quantities, setQuantities] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [domain, setDomain] = useState('');
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const from = params.get('from');

    const user = useRecoilValue(userAtom);

    const downloadImg = () => {
        saveAs(`http://api.qrserver.com/v1/create-qr-code/?data=https://${domain != ''? domain + '.' : ''}moloboost.ca/review/${user.accountID}&size=300x300`);
        // saveAs(`http://api.qrserver.com/v1/create-qr-code/?data=https://moloboost.ca/review/${user.accountID}&size=300x300`);
    };

    const getContact = () => {
        axios.get(`https://molo-boost-backend-27551f6a06a7.herokuapp.com/api/qr-analytics/get/${user.email}`)
            .then(res => {
                setQrData(res.data.data);
                setDates(res.data.dates);
                setDomain(res.data?.domain);
                console.log(res.data);
                
                setQuantities(res.data.quantities);
                setDataLoaded(true);
            }).catch(err => {
                console.log(err);
            });
    };

    useEffect(() => {
        getContact();
    }, [from]);

    return (
        <Flex w="100%" direction={{ base: 'column', md: 'row' }}>
            <Sidebar />
            <Box p={4} w="full">
                <Box w="100%">
                    <Text textAlign={'center'} color='#000' fontSize='2rem' fontWeight='800'>QR Code/Reviews</Text>
                    <Text textAlign={'center'} color='gray.500' fontSize='1rem' fontWeight='500'>
                        Boost your online rank with reviews-- get discovered by more people online.
                    </Text>

                    <Box w="100%" mt={6}>
                        {/* Conditional Layout */}
                        <Flex 
                            direction={{ base: 'column', md: 'row-reverse' }} 
                            align="center" 
                            justify="space-between"
                            gap={6}
                        >
                            {/* QR Chart Section */}
                            <Box flex={2} w="100%">
                                <Skeleton isLoaded={dataLoaded}>
                                    <QRChart dates={dates} quantities={quantities} />
                                </Skeleton>
                            </Box>

                            {/* QR Code Section */}
                            <Box 
                                flex={1} 
                                w="100%" 
                                textAlign="center"
                                mt={{ base: 6, md: 0 }} // Add margin only for mobile screens
                            >
                                <Box
                                    p={4}
                                    borderRadius="8px"
                                    boxShadow="5px 5px 10px 10px rgba(0,0,0,0.05)"
                                    maxW="300px"
                                    margin="0 auto"
                                >
                                    <Image 
                                        // src={`http://api.qrserver.com/v1/create-qr-code/?data=https://moloboost.ca/review/${user.accountID}&size=300x300`} 
                                        src={`http://api.qrserver.com/v1/create-qr-code/?data=https://${domain != ''? domain + '.' : ''}moloboost.ca/review/${user.accountID}&size=300x300`} 
                                        m="0 auto" 
                                        maxW="300px" 
                                    />
                                </Box>
                                <Button 
                                    onClick={() => downloadImg()} 
                                    bg={'#000'} 
                                    color='white' 
                                    mt={4} 
                                    w="60%"
                                >
                                    <AiFillPrinter /> Print QR Code
                                </Button>
                                <Skeleton isLoaded={dataLoaded} mt={4} paddingBottom={16}>
                                    <Text color='#000' fontSize='1.2rem' fontWeight='600'>{qrData.today} scans today</Text>
                                    <Text color='#000' fontSize='1.2rem' fontWeight='600'>{qrData.last_7_days} scans last 7 days</Text>
                                    <Text color='#000' fontSize='1.2rem' fontWeight='600'>{qrData.last_28_days} scans last 28 days</Text>
                                </Skeleton>
                            </Box>
                        </Flex>
                    </Box>
                </Box>
            </Box>
        </Flex>
    );
}
