import React from 'react';
import {
    Box,
    VStack,
    Heading,
    Text,
    Link,
    UnorderedList,
    OrderedList,
    ListItem,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Code,
    Button,
    Flex,
    Icon,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription
} from '@chakra-ui/react';
import { FaGoogle, FaMapMarkerAlt, FaShareSquare } from 'react-icons/fa';

const ReviewManagementGuide = () => {
    const methods = [
        {
            title: "Option 1: Via Google Search",
            steps: [
                "Log into your <a style='text-decoration: underline;' href='https://www.google.com/business/'>Google Business Profile</>.",
                "Look for your business on Google Search and click the link that states the number of reviews you have.",
                "Select the 'Get more reviews' button.",
                "<img src='https://storage.googleapis.com/localunitblog-pictures/article_Image3_2ebf9db028/article_Image3_2ebf9db028.webp'/>",
                "Copy the link and paste in MOLO bost sign up form"

            ],
            icon: FaGoogle
        },
        {
            title: "Option 2: Via Google Business Profile Manager",
            steps: [
                "Log into your <a style='text-decoration: underline;' href='https://www.google.com/business/'>Google Business Profile</>.",
                "Once inside Google Business Profile Manager, choose the business you want to get a link to Google reviews for (if you own multiple businesses, that is).",
                "Scroll down to 'Share your Business Profile'.",
                "Copy the link and paste in MOLO bost sign up form"
            ],
            icon: FaMapMarkerAlt
        },
        {
            title: "Option 3: Via the Google Maps App",
            steps: [
                "Open the Google Maps app and click on your profile icon.",
                "Select 'Your Business Profiles'.",
                "In the Overview section, scroll down to 'Get more reviews' and select 'Share profile'.",
                "Copy the link and share it via SMS, email, WhatsApp, or Facebook."

            ],
            icon: FaShareSquare
        },
        {
            title: "Option 4: Via Place ID Finder",
            steps: [
                "Visit the <a href='https://developers.google.com/maps/documentation/javascript/examples/places-placeid-finder' style='text-decoration: underline;'>Place ID Finder</a> in the Google Maps developer platform.",
                "Search for your business in the search bar.",
                "When your business pops up, copy the place ID series of numbers and letters.",
                "Paste the ID in the URL: https://search.google.com/local/writereview?placeid=YOUR_PLACE_ID",
                "Copy the URL or send it directly via social media and messaging apps."

            ],
            icon: FaGoogle
        }
    ];

    return (
        <Box 
            maxW="container.md" 
            mx="auto" 
            p={6} 
            bg="white" 
            borderRadius="lg" 
            boxShadow="md"
        >
            <VStack spacing={6} align="stretch">
                <Heading 
                    as="h1" 
                    size="xl" 
                    textAlign="center" 
                    color="blue.500"
                    borderBottom="2px" 
                    pb={2}
                >
                    How To Get Your Link to Google Reviews
                </Heading>

                <Alert 
                    status="info" 
                    variant="subtle" 
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                >
                    <AlertIcon boxSize={10} mr={0} />
                    <AlertTitle mt={4} mb={1} fontSize="lg">
                        What is a Google Reviews Link?
                    </AlertTitle>
                    <AlertDescription maxWidth="600px">
                        A Google reviews link connects your customers directly to where they can leave a review for your business.
                    </AlertDescription>
                </Alert>

                <Accordion allowToggle>
                    {methods.map((method, index) => (
                        <AccordionItem key={index}>
                            <AccordionButton _expanded={{ bg: "blue.50", color: "blue.600" }}>
                                <Box flex="1" textAlign="left">
                                    <Flex align="center">
                                        <Icon as={method.icon} mr={3} />
                                        <Heading size="md">{method.title}</Heading>
                                    </Flex>
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                <OrderedList spacing={2}>
                                    {method.steps.map((step, stepIndex) => (
                                        <ListItem key={stepIndex} dangerouslySetInnerHTML={{__html: step}}></ListItem>
                                    ))}
                                </OrderedList>
                            </AccordionPanel>
                        </AccordionItem>
                    ))}
                </Accordion>

                <Box 
                    bg="gray.50" 
                    p={6} 
                    borderRadius="md" 
                    textAlign="center"
                >
                    <Heading size="md" mb={4}>
                        Need More Help?
                    </Heading>
                    <Flex justifyContent="center" gap={4}>
                        <Button 
                            colorScheme="blue" 
                            onClick={() => window.open('https://support.google.com/business', '_blank')}
                        >
                            Google Business Help
                        </Button>
                        <Button 
                            colorScheme="green"
                            onClick={() => window.open('https://support.google.com/business/answer/7035772', '_blank')}
                        >
                            Review Guide
                        </Button>
                    </Flex>
                </Box>
            </VStack>
        </Box>
    );
};

export default ReviewManagementGuide;
