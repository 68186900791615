import {useState} from 'react';
import {
    Flex,
    Divider,
    Avatar,
    Heading,
    Text,
    Box,
} from '@chakra-ui/react';
import { FiUsers } from 'react-icons/fi';
import { ImQrcode } from "react-icons/im";
import { FaRegThumbsUp } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { userAtom } from '../recoil/userAtoms';
import { useRecoilValue } from 'recoil';
import logo from '../files/MOLO boost logo.png';

export default function Sidebar() {
    const user = useRecoilValue(userAtom);
    const [navSize, changeNavSize] = useState("large");

    return (
        <>
            {/* Non-Mobile Sidebar */}
            <Flex
            pos='static'
            left="0"
            h='inherit'
            minHeight={'100vh'}
            boxShadow="0 4px 12px 4px rgba(0, 0, 0, 0.15)"
            bg="rgba(0, 0, 0, 0.15)"
            borderRadius={navSize == "small" ? "0px 0px 0px 15px" : "0px 0px 30px 0px"}
            w={navSize == "small" ? "75px" : "200px"}
            flexDir="column"
            display={{ base: "none", md: "flex" }} /* Hide on mobile */
        >
            <Flex
                p="5%"
                flexDir="column"
                w="100%"
                alignItems={navSize == "small" ? "center" : "flex-start"}
                as="nav"
            >
                <img src={logo} style={{width:'80%', margin:' 0 auto'}}/>
                <Divider display={navSize == "small" ? "none" : "flex"} />
                <Link to='/dashboard/contacts'>
                    <div id={'step3'}>
                      <NavItem navSize={navSize} icon={FiUsers} title="Contacts" />
                    </div>
                </Link>
                <Link to='/dashboard/feedback'>
                    <div id={'step4'}>
                      <NavItem navSize={navSize} icon={FaRegThumbsUp} title="Reviews" />
                    </div>
                </Link>
                <Link to='/dashboard/reviews'>
                    <div id={'step5'}>
                      <NavItem navSize={navSize} icon={ImQrcode} title="QR-Code"/>
                    </div>
                </Link>       
            </Flex>
            <Flex
                p="5%"
                flexDir="column"
                w="100%"
                alignItems={navSize == "small" ? "center" : "flex-start"}
                mb={4}
            >
                <Divider display={navSize == "small" ? "none" : "flex"} />
                <Link to='/dashboard/account'>  
                    <div id='step7'>
                      <Flex mt={4} ml={2} align="center">
                          <Avatar size="sm" src="avatar-1.jpg" />
                          <Flex flexDir="column" ml={4} display={navSize == "small" ? "none" : "flex"}>
                              <Heading as="h3" size="sm">{user.firstName}</Heading>
                              <Text color="gray">Admin</Text>
                          </Flex>
                      </Flex>
                    </div>                
                </Link>
            </Flex>
        </Flex>

            {/* Mobile Bottom Navbar */}
            <Flex
                display={{ base: "flex", md: "none" }} /* Show only on mobile */
                pos="fixed"
                bottom="0"
                left="0"
                w="100%"
                bg="gray.100"
                boxShadow="0 -2px 5px rgba(0,0,0,0.2)"
                justify="space-around"
                align="center"
                p={2}
                zIndex="1000"
            >
                <Link to='/dashboard/contacts'>
                    <Box textAlign="center">
                        <FiUsers size={24} />
                        <Text fontSize="xs">Contacts</Text>
                    </Box>
                </Link>
                <Link to='/dashboard/feedback'>
                    <Box textAlign="center">
                        <FaRegThumbsUp size={24} />
                        <Text fontSize="xs">Reviews</Text>
                    </Box>
                </Link>
                <Link to='/dashboard/reviews'>
                    <Box textAlign="center">
                        <ImQrcode size={24} />
                        <Text fontSize="xs">QR-Code</Text>
                    </Box>
                </Link>
                <Link to='/dashboard/account'>
                    <Box textAlign="center">
                        <Avatar size="xs" src="avatar-1.jpg" />
                        <Text fontSize="xs">Account</Text>
                    </Box>
                </Link>
            </Flex>
        </>
    );
}

function NavItem({ icon: Icon, title }) {
    return (
        <Flex align="center" mb={4}>
            <Icon size={20} />
            <Text ml={4}>{title}</Text>
        </Flex>
    );
}
