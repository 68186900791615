import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { Box } from '@chakra-ui/react';

const QRChart = (props) => {
  const [chartWidth, setChartWidth] = useState(window.innerWidth > 768 ? 700 : window.innerWidth - 50);

  // Update the chart width when the window is resized
  useEffect(() => {
    const handleResize = () => {
      setChartWidth(window.innerWidth > 768 ? 700 : window.innerWidth - 50); // Adjust width for mobile
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Box w="100%" maxW="100%" overflow="hidden" mx="auto">
      <Chart
        options={{
          chart: {
            height: 350,
            type: 'line',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2,
            },
            toolbar: {
              show: false,
            },
          },
          colors: ['#77B6EA'],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'],
              opacity: 0.5,
            },
          },
          markers: {
            size: 3,
          },
          xaxis: {
            categories: props.dates,
            title: {
              text: 'Date',
              style: { fontSize: '0.8rem' },
            },
            labels: {
              style: {
                fontSize: '0.7rem',
              },
            },
          },
          yaxis: {
            title: {
              text: 'Scans',
              style: { fontSize: '0.8rem' },
            },
            labels: {
              style: {
                fontSize: '0.7rem',
              },
            },
          },
          legend: {
            position: 'top',
            horizontalAlign: 'center',
            floating: false,
            offsetY: -10,
            offsetX: 0,
            labels: {
              useSeriesColors: true,
            },
          },
        }}
        series={[
          {
            name: 'QR Scans',
            data: props.quantities,
          },
        ]}
        type="line"
        width={chartWidth}
      />
    </Box>
  );
};

export default QRChart;
