import { 
    Box, 
    Flex,
    Text,
    Input,
    Button,
    Skeleton,
    useToast
} from '@chakra-ui/react';

import Sidebar from '../components/Sidebar';
import { MdManageAccounts } from 'react-icons/md';
import { BiChevronsRight } from "react-icons/bi";
import { FiLogOut } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { userAtom } from '../recoil/userAtoms';
import { useRecoilState } from 'recoil';
import { useState, useEffect } from 'react';
import axios from 'axios';

export default function Dashboard() {
    const [user, setUser] = useRecoilState(userAtom);
    const [reviewLink, setReviewLink] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const toast = useToast();

    // Fetch review link on component load
    useEffect(() => {
        const fetchReviewLink = async () => {
            setIsLoaded(false);
            try {
                const response = await axios.get(
                    `https://molo-boost-backend-27551f6a06a7.herokuapp.com/api/reviews/get/${user.email}`
                );
                setReviewLink(response.data.reviewLink || '');
            } catch (error) {
                toast({
                    title: 'Error fetching review link',
                    description: 'Unable to load review link. Please try again later.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } finally {
                setIsLoaded(true);
            }
        };

        fetchReviewLink();
    }, [user.email, toast]);

    // Validate URL
    const validateURL = (url) => {
        const pattern = new RegExp(
            '^(https?:\\/\\/)?' + // Protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // Domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // Port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // Query string
            '(\\#[-a-z\\d_]*)?$',
            'i'
        );
        return !!pattern.test(url);
    };

    // Save updated review link
    const saveReviewLink = async () => {
        if (!reviewLink) {
            toast({
                title: 'Invalid URL',
                description: 'Please enter a valid URL.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        setIsSaving(true);
        try {
            await axios.post(`https://molo-boost-backend-27551f6a06a7.herokuapp.com/api/reviews/update`, {
                email: user.email,
                reviewLink,
            });
            toast({
                title: 'Review link updated',
                description: 'Your review link has been successfully updated.',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: 'Error saving review link',
                description: 'Unable to save review link. Please try again later.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsSaving(false);
        }
    };

    const getPortalURL = () => {
        setIsLoaded(false);
        axios.post('https://molo-boost-backend-27551f6a06a7.herokuapp.com' + "/api/stripe/customer-portal", {
          email: user.email
      }).then(res => {
        window.location = res.data.redirect;
        setIsLoaded(true);
      }).catch(err => {
        setIsLoaded(true);
        alert('Uh-oh, there was a server error... try again or contact support.')
      });

    }

    return (
        <Flex w="100%">
            <Sidebar />
            <Box p={4} w="full" pb={0}>
                <Box w="100%">
                    <Text textAlign="center" color="#000" fontSize="2rem" fontWeight="800">
                        Account Details
                    </Text>
                    <Text textAlign="center" color="gray.500" fontSize="1rem" fontWeight="500">
                        Update your account details or contact customer support (molostrategies@gmail.com).
                    </Text>

                    <Box w="100%" mt="5vh">
                        <Flex>
                            <Flex
                                m="0 auto"
                                w={["100%", "50%" ]}
                                direction="column"
                                justifyContent="space-evenly"
                                borderRadius="6px"
                            >
                                <Box w='100%' borderBottom='1px solid rgb(140, 140, 140)'>
                                <Skeleton isLoaded={isLoaded}> 
                                    <Flex cursor='pointer' justifyContent={'space-between'} _hover={{bg:'gray.100'}} borderRadius="10px" onClick={() => getPortalURL()}> 
                                        <Flex>
                                            <MdManageAccounts size='2rem' color='#000' style={{marginRight:'15px'}}/>
                                            <Text color='#000' fontSize={'1.6rem'} fontWeight='700'>Account Settings</Text>
                                        </Flex>
                                        <BiChevronsRight color='#000' verticalAlign='bottom' size='2rem'/>
                                    </Flex>
                                </Skeleton>
                            </Box>
                                <Box w="100%" >
                                    <Skeleton isLoaded={isLoaded}>
                                        <Flex
                                            justifyContent="space-between"
                                            _hover={{ bg: 'gray.100' }}
                                            borderRadius="10px"
                                        >
                                            <Flex>
                                                <MdManageAccounts
                                                    size="2rem"
                                                    color="#000"
                                                    style={{ marginRight: '15px' }}
                                                />
                                                <Text color="#000" fontSize="1.6rem" fontWeight="700">
                                                    Review Link
                                                </Text>
                                            </Flex>
                                        </Flex>
                                        <Box mt={4}>
                                            <Input
                                                value={reviewLink}
                                                onChange={(e) => setReviewLink(e.target.value)}
                                                placeholder="Enter your review link"
                                                size="lg"
                                                isDisabled={isSaving}
                                            />
                                            <Button
                                                mt={4}
                                                colorScheme="blue"
                                                onClick={saveReviewLink}
                                                isLoading={isSaving}
                                            >
                                                Save Changes
                                            </Button>
                                        </Box>
                                    </Skeleton>
                                </Box>
                                <Flex alignItems="center" justifyContent="space-between" my={4}>
                                    <Text fontWeight="600" color="#666">Can't find your review link?</Text>
                                    <Button 
                                        size="sm" 
                                        variant="outline" 
                                        colorScheme="blue"
                                        onClick={() => window.open('https://moloboost.ca/gmb-place-id-guide', '_blank')}
                                    >
                                        How-To Guide
                                    </Button>
                                </Flex>

                                <Box w="100%" mt={2}>
                                    <Link to="/login">
                                        <Flex
                                            justifyContent="space-between"
                                            _hover={{ bg: 'gray.100' }}
                                            borderRadius="10px"
                                        >
                                            <Flex>
                                                <FiLogOut
                                                    size="2rem"
                                                    color="#000"
                                                    style={{ marginRight: '15px' }}
                                                />
                                                <Text color="#000" fontSize="1.6rem" fontWeight="700">
                                                    Logout
                                                </Text>
                                            </Flex>
                                            <BiChevronsRight
                                                color="#000"
                                                verticalAlign="bottom"
                                                size="2rem"
                                            />
                                        </Flex>
                                    </Link>
                                </Box>
                            </Flex>
                        </Flex>
                    </Box>
                </Box>
            </Box>
        </Flex>
    );
}
